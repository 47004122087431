<template>
  <div class="my-order">
    <div class="back" @click="back"><< Back to previous page</div>
    <div class="my-top">
      <div class="my-top-num">order number: {{ cart.ordersn }}</div>
      <p v-if="cart.status == 1" style="color: #71b247">Wait for confirm</p>
      <p v-if="cart.status == 2" style="color: #71b247">Wait for delivery</p>
      <p v-if="cart.status == 3">cancelled</p>
      <p v-if="cart.status == 4">Wait for receipt</p>
      <p v-if="cart.status == 5">Completed</p>
      <p v-if="cart.status == 6">Aftersales processing</p>

      <div class="my-top-1">
        <!-- 取消订单 -->
        <div
          class="right-button"
          @click="cancelOrders(cart.id)"
          v-if="cart.status == 1"
        >
          cancellation of order
        </div>
        <!-- 申请售后 -->
        <div
          class="right-button"
          v-if="cart.status == 5"
          @click="path(cart.id, 1)"
        >
          Apply for after sale
        </div>

        <!-- 确认收货 -->
        <div
          class="right-button"
          style="
            color: #71b247;
            border: 1px solid #71b247;
            margin: 0.25rem 0.25rem 0 0.25rem;
            border-radius: 0.1rem;
          "
          v-if="cart.status == 4"
          @click="Confirm(cart.id)"
        >
          Confirm receipt
        </div>
      </div>
    </div>
    <div class="info d-lg-flex d-md-flex">
      <div class="info-block col-lg-7 col-md-7">
        <section>Consignee information</section>
        <section>
          <p>name:</p>
          <span> {{ cart.username }}</span>
        </section>

        <section>
          <p>company:</p>
          <span>{{ $store.state.userinfo.company }}</span>
        </section>
        <section>
          <p>fax:</p>
          <span>{{ $store.state.userinfo.fax }}</span>
        </section>
        <section>
          <p>email:</p>
          <span>{{ $store.state.userinfo.email }}</span>
        </section>
      </div>
      <div class="info-block col-lg-5 col-md-5">
        <section>Order information</section>
        <section>
          <p>order time:</p>
          <span>{{ cart.create_time }}</span>
        </section>
      </div>
    </div>

    <div class="order">
      <div class="all d-flex">
        <section class="col-10">commodity</section>
        <section class="col-2">subtotal</section>
      </div>

      <div
        :class="item.check ? 'single-true' : ''"
        class="d-flex single"
        v-for="(item, index) of cart.ordersgoods"
      >
        <section class="single-check col-2 d-flex">
          <img :src="item.thumb" alt="" />
        </section>
        <section class="col-7 d-flex">
          <p class="col-7">{{ item.description }}</p>
          <p class="col-5 item-title1">{{ item.item_name }}</p>
        </section>
        <section class="col-1 text-center" style="color: #999999">
          x{{ item.num }}
        </section>
        <section class="col-2" style="color: #999999">
          ${{ item.total }}
        </section>
      </div>
    </div>

    <div class="submit">
      <section>
        <p>
          <span style="color: #ff7f00" v-if="cart.ordersgoods">
            {{ cart.ordersgoods.length }}
          </span>
          items, total:
        </p>
        <p>${{ cart.total }}</p>
      </section>
      <section>
        <p>Amount payable:</p>
        <p style="color: #de0e12; font-size: 0.55rem">${{ cart.total }}</p>
      </section>
    </div>
  </div>
</template>
<script>
import {
  getOrdersByid,
  getOrderslist,
  getOrdersCount,
  cancelOrders,
  confirmReceipt,
} from "network/order";

export default {
  created() {
    this.getOrdersByid();
  },

  data() {
    return {
      cart: {},
    };
  },
  methods: {
    getOrdersByid() {
      getOrdersByid(this.$route.query.id).then((res) => {
        if (res.data.code == 1) {
          this.cart = res.data.data;
        }
      });
    },

    back() {
      this.$router.go(-1);
    },

    //申请售后
    path(id, index) {
      this.$router.push({
        path: "/member/ruturnPage",
        query: {
          id,
          index,
        },
      });
    },
    //取消订单
    cancelOrders(id) {
      cancelOrders(id).then((res) => {
        if (res.data.code == 1) {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
          this.$router.go(0);
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },

    //确认收货
    Confirm(id) {
      confirmReceipt(id).then((res) => {
        if (res.data.code == 1) {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
          this.getOrderslist();
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },
  },
};
</script>
<style scoped>
p {
  padding: 0;
  margin: 0;
}
.back {
  font-size: 0.4rem;
  color: #ff7f00;
  cursor: pointer;
  text-decoration: underline;
}
.right-button {
  margin-top: 0.5rem;
}
.my-top {
  border: 1px solid #ededed;
  border-top: 3px solid #71b247;
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  color: #999999;
  font-size: 0.35rem;
  text-align: center;
}
.my-top p {
  font-size: 0.5rem;
  margin-top: 0.75rem;
}

.info {
  font-size: 0.35rem;
  color: #4c4c4c;
  border: 1px solid #e0e0e0;
  padding-bottom: 0.5rem;
}
.info-block:first-child {
  border-right: 1px solid #e0e0e0;
}
.info-block section {
  margin-top: 0.5rem;
  display: flex;
}
.info-block p {
  min-width: 2rem;
}

/** */
.order {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
}

.all {
  padding: 0.25rem;
  background: #f2f2f2;
  font-size: 0.35rem;
  color: #666666;
}
.all section {
  padding: 0;
  margin: 0;
}
.single section {
  padding: 0;
  margin: 0;
}
.single p {
  padding: 0;
  margin: 0;
}
.single {
  font-size: 0.35rem;
  border-bottom: 1px solid #ededed;
  padding: 0.25rem;
}
.single-true {
  background: #ffecd9;
}
.single-check {
  align-items: center;
}
.single-check img {
  width: 2rem;
  height: 2rem;
  margin-left: 0.25rem;
  object-fit: contain !important;
}
.count-totla {
  color: #de0e12;
}
.submit {
  padding: 0 2.5%;
  padding-bottom: 1rem;
}
.submit section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #666666;
  font-size: 0.35rem;
  margin-top: 0.25rem;
}
.submit section p {
  margin-left: 0.5rem;
}
</style>